.ActiveSelector {

    .active-selection {
        color: #723bb1 !important;
        font-weight: 700;

        &-icon {
            color: #723bb1 !important;
            font-weight: 700;
            transform: translateY(-1px);
            margin-right: 7px;
        }
  }
}