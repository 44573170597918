.bold{
    font-weight: bold;
}

.Stack__list {
    display: flex;
    flex-wrap: wrap; 
    gap: 20px;

    &-item {
        // background-color: #7554a0;
        width: 175px;
        min-height: 175px;

        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .2s ease;
        cursor: pointer;
        text-align: center;
        padding: 1rem;
        position: relative;

        &:hover {
            transition: all .2s ease;
            background-color: #b69bdb67;
        }

        &-icon {
            font-size: 30px;
            color: #7554a0;
            &-delete {
                font-size: 20px;
                color: red;
                position: absolute;
                top: 5%;
                right: 5%;
            }
        }

        &.new {
            background-color: #c4ade441;
            gap: 0;
            box-shadow: none;
            transition: all .2s ease;

            &:hover {
                transition: all .2s ease;
                background-color: darken($color: #c4ade467, $amount: 10);

            }

            .Stack__list-item-icon {
                font-size: 25px;
                color: #7554a0;
                margin-bottom: 15px;
            }
        }
    }
}

.Stack__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
        font-size: 18px;
    }
    
    &-backBtn {
        align-self: flex-end;
        margin-left: 5px;
    }

    &-backBtnLower {
        align-self: flex-end;
        margin-left: 5px;
        transform: translateY(40px);
    }
}
