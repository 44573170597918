$time-line-left: 184px;

.timeline{
    margin-top: 10px;
}

.show-more{
    position: absolute;
    left: $time-line-left - 6px;
}

.point-box:before {
    content: "";
    position: absolute;
    width: 1;
    height: 100%;
    background-color: #ced4da;
    width: 0.241rem;
    /* top: 0; */
    left: $time-line-left;
    top: 22px;
}

.point-box:last-child:before {
    background-color: #f8f9fa;
 }
  

.point-box{
    position: relative;
    padding: 15px 0;

    .items{
        display: flex;

        .time{
            font-size: 1.2rem;
            font-weight: 500;
            color: #70657b;
        }

        .circle-pointer{
            cursor: pointer;
        }
        .circle-pointer:hover{
            p{
                background-color: #1a0d26;
            }
        }

        .circle{
            margin: 0 1rem;

            .big-dot{
                height: 13px;
                width: 13px;
                margin: 0;
                position: relative;
                z-index: 1;
            }
        }

        .item-text{
            font-size: 1rem;
            color: #70657b;
        }
    }
}