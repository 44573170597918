.unmanaged-table{
    //height: 400px;
    overflow-y: scroll; 

    table{
        @media(min-width: 1080px){
            table-layout: fixed;
        }
    }
}

.resource-img > img{
    height:20px;
    min-width: auto;
    max-width: 40px;
}

.small-col{
    width:100px;
}