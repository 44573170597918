.layout-sidebar-compact.sidenav-open .main-content-wrap {
    width: calc( 100% - 76px) !important;
}

.home-page-search-bar{
    display: flex;
    align-items: center;
    justify-content: left;
    background: #f8f9fa;
    border: 1px solid #eee;
    border-radius: 20px;
    position: relative;
    padding-bottom: 4px
}