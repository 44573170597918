.assetsTable {
  padding: 20px;

  table {
    border-spacing: 0;
    // border: 1px solid black;

    thead {
      color: #723bb1;
      font-weight: 700;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid rgba(128, 128, 128, 0.3);
    }
  }

  &__col {
    display: flex;
    align-items: center;
    gap: 10px;

    &-sort {
      display: flex;
      flex-direction: column;
      transform: translateY(-1px);
    }
  }

  // sorting arrows
  .sort-by-asc {
    left: 3px;
    display: inline-block;
    width: 0;
    height: 0;
    border: solid 4px transparent;
    margin: 3px 3px 0 3px;
    background: transparent;
    border-bottom: solid 5px #222;
    border-top-width: 0;
    opacity: .3;
    transition: opacity .2s ease;

    &.active-sort {
      opacity: .8;
    }
  }

  .sort-by-desc {
    left: 3px;
    display: inline-block;
    width: 0;
    height: 0;
    border: solid 4px transparent;
    margin: 3px 3px 0 3px;
    background: transparent;
    border-top: solid 5px #333;
    border-bottom-width: 0;
    opacity: .3;
    transition: opacity .2s ease;

    &.active-sort  {
      opacity: .8;
    }

  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 10px 0;
  }
}
