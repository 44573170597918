.CreateEnvironment {

    .form-group {
        label {
            margin-bottom: 0 ;
        }
    }

    &__form {
        &-radioBox {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            gap: 7px;

            input {
                margin-right: 5px;
                transform: translateY(1px);
            }
        }
    }
}