.cb-copy-icon {
    cursor: pointer;
}

.cb-copy-icon:hover, .cb-copied{
    color:#663399;
}

.ctb {
    display: flex;
    flex-direction: row;
    margin: 1rem auto;
    cursor: pointer;
    border: 1px solid #555;
    border-radius: 3px;
    background-color: #f5f5f5;
    padding: 1rem 2rem 1rem 1rem;
    align-items: center;

    pre {
        overflow-x: hidden;
        white-space: pre-wrap;
        word-break: break-word;
        flex: 1;
        margin: 0;
    }

    i {
        display: block;
        margin-left: 1.5rem;
    }

    .copied {
        background-color: #555;
        color: #fff;
        padding: 0 .75rem;
        border-radius: 3px;
        margin-left: 1.5rem;
    }
}
