.users {
  .card + .card {
    margin-top: 2rem;
    position: relative;
  }

  // test only - if good extract to custom button component!
  .btn-infralight {
    background-color: #6b399d;
    color: white;
    transition: box-shadow 0.2s ease;
    position: absolute;
    top: 20px;
    right: 20px;

    &:hover {
      box-shadow: 0 8px 25px -8px #6b399d;
      transition: box-shadow 0.2s ease;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(107, 57, 157, 0.5);
      transition: box-shadow 0.2s ease;
    }
  }

  // test for badges
  .infralight-badge {
    padding: 0.35em 0.9em;
    background-color: #9a70c6;
    color: white;
  }

  .btn-infralight-second {
    background-color: #6b399d;
    color: white;
    transition: box-shadow 0.2s ease;
    position: absolute;
    top: 20px;
    right: 200px;

  }
}

.alert-card {
    padding: 1rem;

    p {
        margin: 0;
    }
}
