#navigation-padding{
    padding-top:80px;
}

.tab-active {
    background-color: lighten($color: #322740, $amount: 10);
    // border-left: 2px solid white;
}

.layout-sidebar-compact .sidebar-left .navigation-left .nav-item .nav-item-hold .nav-text {
    display: block !important;
}

.nav-item-hold {
    transition: background-color .2s ease;
    // display: flex !important;
    // align-items: center;

    .nav-text {
        margin-top: 8px;
        font-size: 12px;
    }
    
    &:hover {
        background-color: lighten($color: #322740, $amount: 5);
        transition: background-color .2s ease;
    }
}

.active {
    border: none !important;
}

.hidden{
    display: none !important;
}