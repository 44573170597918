.CreateStack {

    .form-group {
        label {
            margin-bottom: 0 ;
        }
    }

    &__form {
        &-radioBox {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            gap: 7px;

            input {
                margin-right: 5px;
                transform: translateY(1px);
            }
        }
        &-radioTile {
            display: flex;
            flex-direction: row;
            gap: 20px;
            width: 100%;
            margin-top: 10px;
        }
    }
}

.modal-xlg{
    max-width: 1080px !important;
}

.wizardSubmitLine{
    margin-bottom: 7px;
    &-files {
        margin-top: 20px;
    }
}

.selectIacProvider{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    padding: 20px;
    font-weight: bold;
    cursor: pointer;
    transition: all .2s ease;

    &:hover {
        background-color: lighten(mediumpurple,15);
    }
    &.active {
        background-color: lighten(mediumpurple,10);
    }
    &.disabled {
        background-color: lighten(#999,30);
        color: gray;
        cursor: not-allowed;
        opacity: .7;
        img { filter: grayscale(100%); width: 50px; height: auto}
    }
}