#create_account {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

#create_account > form.card {
    max-width: 420px;
    padding: 1.5rem;
}

#create_account img {
    margin-bottom: 2rem;
}

#create_account p {
    font-weight: bold;
}
