.no-data{
    text-align: center;

    img{
        max-height: 250px;
        opacity: 0.8;
    }

    .description{
        font-size: 24px;
        margin-top: 6px;
    }
}