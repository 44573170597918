.assetsInventoryHeader {
    padding: 15px;

    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;

    &__dropDownFilter {
        width: 100px;
    }

    &__search{
        position: relative;
        width: 200px;

        &-icon {
            position: absolute;
            right: 2px;
            top: 50%;
            transform: translate(-50%,-50%);
            opacity: .5;
        }
    }
}