.bold{
    font-weight: bold;
}

.Stack__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; 

    &-item {
        width: 175px;
        min-height: 175px;

        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .2s ease;
        cursor: pointer;
        text-align: center;
        padding: 1rem;
        position: relative;

        &:hover {
            transition: all .2s ease;
            background-color: #b69bdb67;
        }

        &-icon {
            font-size: 30px;
            color: #7554a0;
            &-delete {
                font-size: 20px;
                color: red;
                position: absolute;
                top: 5%;
                right: 5%;
            }
        }

        &.new {
            background-color: #c4ade441;
            gap: 0;
            box-shadow: none;
            transition: all .2s ease;

            &:hover {
                transition: all .2s ease;
                background-color: darken($color: #c4ade467, $amount: 10);

            }

            .Stack__list-item-icon {
                font-size: 25px;
                color: #7554a0;
                margin-bottom: 15px;
            }
        }
    }

    &-new {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 5px 10px;
        border-radius: 5px;
        background-color: #c4ade441;
        gap: 0;
        box-shadow: none;
        transition: all .2s ease;

        &:hover {
            transition: all .2s ease;
            background-color: darken($color: #c4ade467, $amount: 10);

        }

        &-icon {
            font-size: 20px;
            color: #7554a0;
            margin-right: 10px;
        }
    }
}

.Stack__list {

    &-row {
        cursor: pointer;
        transition: all .2s ease;

        &:hover {
            transition: all .2s ease;
            background-color: #c4ade441;
        }
    }
}

.Stack__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
        font-size: 18px;
    }
    
    &-backBtn-icon {
        margin-left: 5px;
    }
}
