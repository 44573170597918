body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}

.ml {
    margin-left: 1rem;
}
