@import "../../public/dist-assets/fonts/kubernetes/kubernetes.css";

.card-info{
    position: absolute;
    top: 0;
    right: 0;
}

.card-info > i {
    font-size: 16px !important;
}

.card-box {
    margin-top: 4px;
    margin-bottom: 4px;

    i{
        color: #6633A0;
    }
}

.card-disabled
{
    opacity: 0.6;
    cursor: not-allowed;
}