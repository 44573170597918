.table-full-width{
    width:100%;
}

.table {
    .thead-purple {
      th {
        color: #fff;
        background-color: #663399;
        border-color: #5b5164;
      }
    }

    &.isMinimialPurple {
      thead {
        th {
          color: #6b399d;
          border: transparent;
          padding-bottom: 0.25rem;
        }
      }
    }
}

