.ansi-code{
    display: flex;
  flex-direction: column;
}

.ansi-code > code{
    display: flex;
    flex-direction: column;
}

.ansi-code > code > span{
    white-space: pre-line;
}