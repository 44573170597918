.auth-layout-wrap .auth-content img {
    max-width: 360px;
}
.auth-layout-wrap .alert-card {
    margin-top: 2rem;
    padding: 1rem;
}
.auth-layout-wrap .alert-card p {
    font-size: 1.2rem;
}
.auth-layout-wrap .alert-card p.strong {
    font-size: 1.6rem;
    font-weight: bold;
}
a.logout {
    display: inline-block;
    margin: 1rem auto;
}
